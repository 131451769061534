import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    email: "",
    companies: [],
    selectedCompany: null,
    planType: null,
    isNewUser: false,
  },
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setCompanies: (state, action) => {
      state.companies = action.payload;
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    setPlanType: (state, action) => {
      state.planType = action.payload;
    },
    setIsNewUser: (state, action) => { // Añadimos un reductor para actualizar isNewUser
      state.isNewUser = action.payload;
    },
  },
});

export const { setEmail, setCompanies, setSelectedCompany, setPlanType, setIsNewUser } = userSlice.actions;

export default userSlice.reducer; // Exportación por defecto del reductor