import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./HomeHeader.module.css";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../../assets/Animations/SafeCon.json";

export default function HomeHeader() {
  const { t } = useTranslation();
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [showLottie, setShowLottie] = useState(window.innerWidth > 768);

  const words = t("web.landing.homeHeader.rotatingWords", {
    returnObjects: true,
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const handleResize = () => {
      setShowLottie(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2500);
    return () => clearInterval(interval);
  }, [words.length]);

  return (
    <div className={styles.fill}>
      <div className={styles.fondoContainer}>
        <div className={styles.textoContainer}>
          <ul className={styles.descripcionEmpresa}>
            <div className={styles.titleheader}>
              <h1 className={styles.nombreEmpresa}>
                {t("web.landing.homeHeader.companyName")}
              </h1>
              <h1 className={styles.nombreEmpresa}>
                <span className={styles.palabraGris}>
                  {t("web.landing.homeHeader.legalDocuments")}
                </span>
              </h1>
              <div className={styles.cambiandoPalabras}>
                {words[currentWordIndex]}
              </div>
            </div>
            <p className={styles.description}>
              {t("web.landing.homeHeader.description")}
            </p>
            <Link
              to="https://calendly.com/gerardo-safecon/conoce-safecon-con-nuestro-ceo"
              className={styles.buttonFee}
            >
              {t("web.landing.homeHeader.requestDemo")}
            </Link>
          </ul>
        </div>
        {showLottie && (
          <div className={styles.imagenContainer}>
            <Lottie
              isClickToPauseDisabled={true}
              options={defaultOptions}
              width={"100%"}
            />
          </div>
        )}
      </div>
    </div>
  );
}
