import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./Confirmation.module.css";
import { IoCheckmarkCircleOutline } from "react-icons/io5";

export default function PaymentConfirmation() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has("email")) {
      navigate("/"); // Redirige a la página de inicio si no se accede correctamente
    }
  }, [location, navigate]);

  const email = new URLSearchParams(location.search).get("email");

  return (
    <div className={styles.container}>
      
      <IoCheckmarkCircleOutline className={styles.iconcheck} />

      
      <h2 className={styles.title}>Datos bancarios procesados correctamente</h2>
      <p className={styles.message}>
        Hemos enviado un correo a <span className={styles.email}>{email}</span> para que continues creando tu empresa en SafeCon.
      </p>
    </div>
  );
}
