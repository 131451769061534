import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n.js";
import { registerLicense } from "@syncfusion/ej2-base";
import { ChakraProvider, defaultSystem } from "@chakra-ui/react";

registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXZec3VQR2VcVUx0WEI="
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider value={defaultSystem}>
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </React.StrictMode>
  </ChakraProvider>
);
