import React from "react";
import styles from "./Privacy.module.css"; // Importamos el CSS modular
import { useTranslation, Trans } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>{t("web.policies.privacy.title")}</h1>
      <p className={styles.lastUpdated}>
        {t("web.policies.privacy.lastUpdated")}
      </p>

      <section>
        <h2 className={styles.subheader}>
          {t("web.policies.privacy.introduction.header")}
        </h2>
        <p>{t("web.policies.privacy.introduction.description")}</p>
      </section>

      <section>
        <h2 className={styles.subheader}>
          {t("web.policies.privacy.dataCollection.header")}
        </h2>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <Trans i18nKey="web.policies.privacy.dataCollection.items.contactInfo" />
          </li>
          <li className={styles.listItem}>
            <Trans i18nKey="web.policies.privacy.dataCollection.items.accountInfo" />
          </li>
          <li className={styles.listItem}>
            <Trans i18nKey="web.policies.privacy.dataCollection.items.usageData" />
          </li>
          <li className={styles.listItem}>
            <Trans i18nKey="web.policies.privacy.dataCollection.items.documentData" />
          </li>
        </ul>
      </section>

      <section>
        <h2 className={styles.subheader}>
          {t("web.policies.privacy.useOfData.header")}
        </h2>
        <p>
          <Trans i18nKey="web.policies.privacy.useOfData.items.services" />
        </p>
        <p>
          <Trans i18nKey="web.policies.privacy.useOfData.items.communication" />
        </p>
        <p>
          <Trans i18nKey="web.policies.privacy.useOfData.items.compliance" />
        </p>
      </section>

      <section>
        <h2 className={styles.subheader}>
          {t("web.policies.privacy.confidentiality.header")}
        </h2>
        <p>
          <Trans i18nKey="web.policies.privacy.confidentiality.items.serviceProviders" />
        </p>
        <p>
          <Trans i18nKey="web.policies.privacy.confidentiality.items.legalAdvisors" />
        </p>
        <p>
          <Trans i18nKey="web.policies.privacy.confidentiality.items.governmentAuthorities" />
        </p>
      </section>

      <section>
        <h2 className={styles.subheader}>
          {t("web.policies.privacy.security.header")}
        </h2>
        <p>{t("web.policies.privacy.security.description")}</p>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <Trans i18nKey="web.policies.privacy.security.items.encryption" />
          </li>
          <li className={styles.listItem}>
            <Trans i18nKey="web.policies.privacy.security.items.decryptionKey" />
          </li>
          <li className={styles.listItem}>
            <Trans i18nKey="web.policies.privacy.security.items.decentralizedStorage" />
          </li>
          <li className={styles.listItem}>
            <Trans i18nKey="web.policies.privacy.security.items.accessControl" />
          </li>
          <li className={styles.listItem}>
            <Trans i18nKey="web.policies.privacy.security.items.audits" />
          </li>
        </ul>
      </section>

      <section>
        <h2 className={styles.subheader}>
          {t("web.policies.privacy.privacyRights.header")}
        </h2>
        <p>{t("web.policies.privacy.privacyRights.description")}</p>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <Trans i18nKey="web.policies.privacy.privacyRights.items.access" />
          </li>
          <li className={styles.listItem}>
            <Trans i18nKey="web.policies.privacy.privacyRights.items.rectification" />
          </li>
          <li className={styles.listItem}>
            <Trans i18nKey="web.policies.privacy.privacyRights.items.erasure" />
          </li>
          <li className={styles.listItem}>
            <Trans i18nKey="web.policies.privacy.privacyRights.items.restriction" />
          </li>
          <li className={styles.listItem}>
            <Trans i18nKey="web.policies.privacy.privacyRights.items.objection" />
          </li>
          <li className={styles.listItem}>
            <Trans i18nKey="web.policies.privacy.privacyRights.items.portability" />
          </li>
        </ul>
        <p>
          <Trans i18nKey="web.policies.privacy.privacyRights.extra.beforeLink" />
          <a href="mailto:support@safecon.app">support@safecon.app</a>
          <Trans i18nKey="web.policies.privacy.privacyRights.extra.afterLink" />
        </p>
      </section>

      <section>
        <h2 className={styles.subheader}>
          {t("web.policies.privacy.dataRetention.header")}
        </h2>
        <p>{t("web.policies.privacy.dataRetention.description")}</p>
      </section>

      <section>
        <h2 className={styles.subheader}>
          {t("web.policies.privacy.policyChanges.header")}
        </h2>
        <p>
          <Trans i18nKey="web.policies.privacy.policyChanges.description" />
        </p>
      </section>

      <section>
        <h2 className={styles.subheader}>
          {t("web.policies.privacy.contact.header")}
        </h2>
        <p>
          <Trans i18nKey="web.policies.privacy.contact.description.beforeLink" />
          <a href="mailto:support@safecon.app">support@safecon.app</a>
          <Trans i18nKey="web.policies.privacy.contact.description.afterLink" />
        </p>
      </section>

      <section>
        <h2 className={styles.subheader}>
          {t("web.policies.privacy.policyAcceptance.header")}
        </h2>
        <p>{t("web.policies.privacy.policyAcceptance.description")}</p>
      </section>

      <section>
        <h2 className={styles.subheader}>
          {t("web.policies.privacy.spainAdditional.header")}
        </h2>
        <p>
          <Trans i18nKey="web.policies.privacy.spainAdditional.items.dpo.beforeLink" />
          <a href="mailto:support@safecon.app">support@safecon.app</a>
          <Trans i18nKey="web.policies.privacy.spainAdditional.items.dpo.afterLink" />
        </p>

        {/* Derechos Adicionales y enlace a la AEPD */}
        <p>
          <Trans i18nKey="web.policies.privacy.spainAdditional.items.aepdRights.beforeLink" />
          <a
            href="https://www.aepd.es"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.aepd.es
          </a>
          <Trans i18nKey="web.policies.privacy.spainAdditional.items.aepdRights.afterLink" />
        </p>
      </section>

      <section>
        <h2 className={styles.subheader}>
          {t("web.policies.privacy.readingAcceptance.header")}
        </h2>
        <p>{t("web.policies.privacy.readingAcceptance.description")}</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
