import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
} from "react-icons/io";

export default function Content1({ onAnswer, emisor }) {
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const handleAnswer = (answer) => {
    setSelectedAnswer(answer);
    onAnswer(answer); // Envía true o false al componente padre
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "60vh",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h6"
        sx={{ mb: 11, fontWeight: "bold", color: "#333", fontSize: "1.7rem" }}
      >
        ¿Estás conforme con el documento enviado por {emisor}?
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%",
          maxWidth: 300,
        }}
      >
        <Button
          variant={selectedAnswer === false ? "contained" : "outlined"}
          onClick={() => handleAnswer(false)} // Envía `false` si se elige "No"
          size="medium"
          fullWidth
          sx={{
            display: "flex",
            justifyContent: "space-between",
            py: 1.5,
            fontSize: "1rem",
            borderRadius: "25px",
            textTransform: "none",
            backgroundColor:
              selectedAnswer === false ? "#007BFF" : "transparent",
            borderColor: "#007BFF",
            color: selectedAnswer === false ? "#fff" : "#007BFF",
            "&:hover": {
              background: `linear-gradient(90deg,#1976d2, #61D1B5 )`,
              color: "#fff",
            },
          }}
        >
          No <IoIosCloseCircleOutline style={{ fontSize: "25px" }} />
        </Button>
        <Button
          variant={selectedAnswer === true ? "contained" : "outlined"}
          onClick={() => handleAnswer(true)} // Envía `true` si se elige "Sí"
          size="medium"
          fullWidth
          sx={{
            display: "flex",
            justifyContent: "space-between",
            py: 1.5,
            fontSize: "1rem",
            borderRadius: "25px",
            textTransform: "none",
            backgroundColor:
              selectedAnswer === true ? "#007BFF" : "transparent",
            borderColor: "#007BFF",
            color: selectedAnswer === true ? "#fff" : "#007BFF",
            "&:hover": {
              background: `linear-gradient(90deg,#1976d2, #61D1B5 )`,
              color: "#fff",
            },
          }}
        >
          Sí <IoIosCheckmarkCircleOutline style={{ fontSize: "25px" }} />
        </Button>
      </Box>
    </Box>
  );
}
