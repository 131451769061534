import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  Alert,
  TextField,
} from "@mui/material";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Filter,
  Page,
  Selection,
} from "@syncfusion/ej2-react-grids";
import { FiDownload, FiTrash } from "react-icons/fi";
import { useMutation, gql, useQuery } from "@apollo/client";
import Skeleton from "../../../assets/Animations/Skeleton";
import styled from "styled-components";

const UPLOAD_ANEXO = gql`
  mutation UploadAnexo($file: Upload!, $documentKey: String!) {
    uploadAnexo(file: $file, documentKey: $documentKey) {
      success
      message
    }
  }
`;

const OBTENER_ANEXOS = gql`
  query ObtenerAnexos($documentKey: String!) {
    obtenerAnexos(documentKey: $documentKey) {
      titulo
      enlace
    }
  }
`;

const DELETE_ANEXO = gql`
  mutation DeleteAnexo($documentKey: String!, $filename: String!) {
    deleteAnexo(documentKey: $documentKey, filename: $filename) {
      success
      message
    }
  }
`;

const SkeletonContainer = styled.div`
  display: flex;
  gap: 0px; /* Espacio opcional entre los skeletons */
`;

const Content2 = forwardRef(({ documentKey }, ref) => {
  const [existingDocuments, setExistingDocuments] = useState([]);
  const [newDocuments, setNewDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const fileInputRef = useRef(null);
  const [showMessage, setShowMessage] = useState(false);
  const [operationSuccess, setOperationSuccess] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [deleteAnexo, { loading: deleting }] = useMutation(DELETE_ANEXO, {
    onCompleted: (data) => {
      if (data.deleteAnexo.success) {
        setOperationSuccess(true);
        setAlertMessage("Anexo eliminado con éxito");
        refetch();
      } else {
        setOperationSuccess(false);
        setAlertMessage("Error al eliminar el anexo");
      }
      setShowMessage(true);
    },
    onError: () => {
      setOperationSuccess(false);
      setAlertMessage("Error al eliminar el anexo");
      setShowMessage(true);
    },
  });

  const {
    data: dataquery,
    loading: loadingquery,
    refetch,
  } = useQuery(OBTENER_ANEXOS, {
    variables: { documentKey },
  });

  useEffect(() => {
    if (dataquery && dataquery.obtenerAnexos) {
      const docs = dataquery.obtenerAnexos.map((doc) => ({
        ...doc,
        name: doc.titulo,
        enlace: doc.enlace,
        id: doc.titulo,
      }));
      setExistingDocuments(docs);
      setFilteredDocuments(docs);
    }
  }, [dataquery]);

  // Exponer la función getAnexoData a través de la referencia
  useImperativeHandle(ref, () => ({
    getAnexoData: () => {
      const allDocuments = [...existingDocuments, ...newDocuments].map(
        (doc) => doc.name
      );
      // Usar un Set para eliminar duplicados
      return {
        anexos: Array.from(new Set(allDocuments)),
      };
    },
  }));

  const [uploadAnexo, { loading: uploadinganexo, error }] = useMutation(
    UPLOAD_ANEXO,
    {
      onCompleted: (data) => {
        if (data.uploadAnexo.success) {
          setOperationSuccess(true);
          setAlertMessage("Documento guardado con éxito");
          refetch(); // Actualiza la lista de documentos
        } else {
          setOperationSuccess(false);
          setAlertMessage("Error al guardar el documento");
        }
        setShowMessage(true);
      },
      onError: () => {
        setOperationSuccess(false);
        setAlertMessage("Error al guardar el documento");
        setShowMessage(true);
      },
    }
  );

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const newDocument = {
      name: file.name,
      file: file,
      enlace: URL.createObjectURL(file),
      id: file.name,
    };

    setNewDocuments((prevDocs) => [...prevDocs, newDocument]);

    try {
      await uploadAnexo({
        variables: { file, documentKey },
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleSearchChange = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    if (term) {
      setFilteredDocuments(
        [...existingDocuments, ...newDocuments].filter((doc) =>
          doc.name.toLowerCase().includes(term)
        )
      );
    } else {
      setFilteredDocuments([...existingDocuments, ...newDocuments]);
    }
  };

  const downloadDocument = (url, fileName) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName || "download";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleDeleteDocument = (filename) => {
    deleteAnexo({ variables: { documentKey, filename } });
  };

  const downloadButtonTemplate = (props) => (
    <IconButton onClick={() => downloadDocument(props.enlace, props.name)}>
      <FiDownload />
    </IconButton>
  );

  const deleteButtonTemplate = (props) => (
    <IconButton onClick={() => handleDeleteDocument(props.name)}>
      <FiTrash />
    </IconButton>
  );

  return (
    <Box
      sx={{
        mt: 3,
        mb: 2,
        height: "100%",
        maxHeight: "calc(100% - 64px)",
        overflow: "auto",
      }}
    >
      <Typography variant="h6" component="h2">
        Anexos del documento:
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => fileInputRef.current.click()}
            disabled={uploadinganexo}
            sx={{
              height: "40px",
              background: `linear-gradient(90deg,#1976d2, #61D1B5 )`,
              textTransform: "none",
            }}
          >
            {uploadinganexo ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "+ Añadir Anexo"
            )}
          </Button>
          {showMessage && (
            <Alert
              onClose={() => setShowMessage(false)}
              severity={operationSuccess ? "success" : "error"}
              sx={{
                ml: 2,
                minWidth: "200px",
                height: "40px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {alertMessage}
            </Alert>
          )}
        </Box>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Buscar anexo..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Box>
      <input
        type="file"
        onChange={handleFileUpload}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      <Box sx={{ mt: 3, mb: 2 }}>
        {loadingquery ? (
          <SkeletonContainer>
            <Skeleton />
            <Skeleton />
          </SkeletonContainer>
        ) : (
          <GridComponent
            dataSource={filteredDocuments}
            allowPaging={true}
            pageSettings={{ pageSize: 5, pageCount: 5 }}
            height="37vh"
          >
            <ColumnsDirective>
              <ColumnDirective field="name" headerText="Anexo" width="auto" />
              <ColumnDirective
                headerText="Descargar"
                template={downloadButtonTemplate}
                width="auto"
              />
            </ColumnsDirective>
            <Inject services={[Selection, Page, Filter]} />
          </GridComponent>
        )}
      </Box>
    </Box>
  );
});

export default Content2;
