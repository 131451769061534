import React from "react";
import styles from "./Banner.module.css"; 
import aws from "../../../assets/images/aws.webp";
import lanzadera from "../../../assets/images/lanzadera.webp";
import google from "../../../assets/images/googlecloud.webp";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.bannerContainer}>
      <h1>{t("web.landing.banner.bannerTitle")}</h1>
      <div className={styles.logoMarquee}>
        <div className={styles.logoSlide}>
          <img src={aws} alt="Logo Partner 1" className={styles.logo} />
          <img
            src={lanzadera}
            alt="Logo Partner 2"
            className={styles.logolanza}
          />
          <img src={google} alt="Logo Partner 3" className={styles.logo} />
        </div>
      </div>
    </div>
  );
};

export default Banner;
