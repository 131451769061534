import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./PaymentError.module.css";

export default function PaymentError() {
  const navigate = useNavigate();

  return (
    <div className={styles.errorSection}>
      <h2>Hubo un problema con su pago</h2>
      <p>Por favor, intente realizar el pago de nuevo. Si el problema persiste, contacta con soporte : support@safecon.app</p>
      <button
        className={styles.submitButton}
        onClick={() => navigate(`/pricing`)} // Redirige de vuelta al formulario
      >
        Volver
      </button>
    </div>
  );
}
