// FanSpinner.js
import React from "react";
import styles from "./Fallback.module.css";
import { Oval } from "react-loader-spinner";

const FanSpinner = () => {
  return (
    <div className={styles.spinner}>
      <Oval
        visible={true}
        height="80"
        width="80"
        color="#98ffe5"
        ariaLabel="oval-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};

export default FanSpinner;
