import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

const ProtectedRoute = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  if (!user) {
    return <div>La API estará disponible próximamente</div>;
  }

  return (
    <div>
      {/* Aquí puedes cargar cualquier contenido adicional que desees mostrar si el usuario está autenticado */}
      <p>
        Bienvenido, {user.signInDetails.loginId}! La API estará disponible
        próximante{" "}
      </p>
    </div>
  );
};

export default ProtectedRoute;
