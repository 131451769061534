import React from "react";
import styles from "./ScreenBlocker.module.css"; // Importamos los estilos modularizados
import Lottie from "react-lottie";
import animationData from "../assets/Animations/SafeCon.json"; // Asumimos que tienes esta animación

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ScreenBlocker = () => {
  return (
    <div className={styles.screenBlocker}>
      <div className={styles.blockerContent}>
        <div className={styles.textContainer}>
          <h1>App móvil y para tablets próximamente</h1>
          <p>
            La aplicación estará disponible en dispositivos móviles y tablets en
            el futuro. Por favor, acceda desde una pantalla más grande.
          </p>
        </div>
        <div className={styles.animationContainer}>
          <Lottie
            isClickToPauseDisabled={true}
            options={defaultOptions}
            width={"100%"}
          />
        </div>
      </div>
    </div>
  );
};

export default ScreenBlocker;
