import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Cargar traducciones desde archivos JSON
import translationEN from './languages/en/translation.json';
import translationES from './languages/es/translation.json';

i18n
  .use(LanguageDetector) // Detecta el idioma del navegador
  .use(initReactI18next) // Integración con React
  .init({
    resources: {
      en: { translation: translationEN },
      es: { translation: translationES },
    },
    fallbackLng: 'en', // Idioma por defecto si el navegador no es español ni inglés
    interpolation: {
      escapeValue: false, // React ya escapa valores
    },
    detection: {
      // Configuración de detección para que solo lo haga la primera vez
      order: ['localStorage', 'navigator'], // Prioridad: primero buscar en localStorage, luego en el navegador
      caches: ['localStorage'], // Guarda el idioma detectado en localStorage
    },
  });

export default i18n;
